import React from 'react';
import PropTypes from 'prop-types';
//Components
import Input from '../Partials/Input';
import InputHeader from '../Partials/InputHeader';
//Hooks
import useErrors from '../Hooks/useErrors';
//Functions
import { formatFieldId } from '../Utilities/Helper';
//PropTypes
const proptypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    rootClass: PropTypes.string,
};

/**
 * Gravity Forms TextField Component
 * @param {PropTypes}
 * @returns
 */
const TextField = ({ field, onChange, errors, rootClass = 'text-field' }) => {
    const name = 'input_' + formatFieldId(field.id);
    const fieldErrors = useErrors(errors, name);

    return (
        <div className={`field ${rootClass} ${field.cssClass}`}>
            <InputHeader
                field={field}
                fieldName={name}
                errors={fieldErrors}
                rootClass={rootClass}
            />
            <Input
                type="text"
                name={name}
                cssClass={`${rootClass}__input${
                    fieldErrors.length > 0 ? ' input-error' : ''
                }`}
                handleOnChange={e => {
                    onChange(name, e.target.value);
                }}
                placeholder={field.placeholder}
            />
        </div>
    );
};

TextField.propTypes = proptypes;
export default TextField;
